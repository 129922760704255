<template>
  <div class="msg_container">
    <h5 class="mt-heading mt-heading--s mt-heading--center mt-heading--underline">
      {{ $t('ui.message.ohNo') }}
    </h5>
    <span style="font-size: 100px">&#128533;</span>
    <p>
      {{ $t('ui.message.productNotFound') }}
    </p>
    <p class="text-center">{{ $t('ui.message.sorry') }}</p>
  </div>
</template>

<script>
export default {
  name: 'PageNotFound',
  data() {
    return {
      loading: false
    };
  },
  computed: {},
  methods: {}
};
</script>
